@font-face {   
    font-family: 'Aladin';    
    src: url('../../../public/assets/fonts/Aladin/Aladin-Regular.ttf');
}
@font-face {   
    font-family: 'Arizonia';    
    src: url('../../../public/assets/fonts/Arizonia/Arizonia-Regular.ttf');
}
@font-face {   
    font-family: 'Audiowide';    
    src: url('../../../public/assets/fonts/Audiowide/Audiowide-Regular.ttf');
}
@font-face {   
    font-family: 'ContrailOne';    
    src: url('../../../public/assets/fonts/ContrailOne/ContrailOne-Regular.ttf');
}
@font-face {   
    font-family: 'Explora';    
    src: url('../../../public/assets/fonts/Explora/Explora-Regular.ttf');
}
@font-face {   
    font-family: 'GreatVibes';    
    src: url('../../../public/assets/fonts/GreatVibes/GreatVibes-Regular.ttf');
}
@font-face {   
    font-family: 'Kavivanar';    
    src: url('../../../public/assets/fonts/Kavivanar/Kavivanar-Regular.ttf');
}
@font-face {   
    font-family: 'LaBelleAurore';    
    src: url('../../../public/assets/fonts/LaBelleAurore/LaBelleAurore-Regular.ttf');
}

@font-face {   
    font-family: 'PetitFormatScript';    
    src: url('../../../public/assets/fonts/PetitFormatScript/PetitFormalScript-Regular.ttf');
}
@font-face {   
    font-family: 'SourceSansPro';    
    src: url('../../../public/assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf');
}


/*
https://coolors.co/0a122a-a5d8ff-ff715b-f6ae2d-003844  : #0A122A, #A5D8FF, #FF715B, #F6AE2D, #003844
https://coolors.co/dcdcdd-c5c3c6-46494c-4c5c68-1985a1  : 
https://coolors.co/1c2321-7d98a1-5e6572-a9b4c2-eef1ef  
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body * {font-family: 'SourceSansPro',sans-serif; }
body h2 { font-size: 3rem; text-transform: uppercase; text-align: center; font-weight: bold; color: #111;}
body h2:after {content: '';display: block; position: relative; top: .5rem;left: 50%; transform: translateX(-50%); height: 7px; width: 4rem; background-color: #F6AE2D; border-radius: 2.5rem;}
body h3 {font-size: 2rem; font-weight: 900; }
body h2, body h3, body h4 {color : #0A122A;}
body p {margin-top: 1.5rem;line-height: 1.9;font-size:1.25rem; color: rgb(66, 66, 66);}

/*********************************
 **     HEADER
 *********************************/
ion-header {/*background-color: #F6AE2D; #FFF7E8; #f5ebda;*/ background-color:#ffe3b3; box-shadow: .1em  -.2em .4em .6em gray; }
ion-header ion-grid > ion-row:first-of-type {margin-top: 4.5em; margin-bottom:1.3em;}
ion-header .header-logo { margin-top: .1em;}
ion-header .header-logo ion-img::part(image){ padding: .1em; height: 7em; /*background-color: #F6AE2D; border:solid thin gray; width:30%; border: solid thin black; border-radius: 111em;*/}
/*
ion-header h1, ion-header h2 {margin: 0; padding: 0; color: white!important; }
ion-header h1 {font-family: Explora; font-weight: bold; font-size: 3.75em; font-weight: 600; text-align: center; color: rgb(19, 19, 24); }
ion-header h2 {font-size: 1.1em;  font-style: italic; font-weight: 100; text-align: center; color: rgb(117, 117, 117);}
*/
ion-header ion-col {max-height:7em; padding-bottom: 1em;}

ion-header .headline-one, ion-header .headline-two {margin: 0; padding: 0; line-height: 0.8; }
ion-header .headline-one {font-family: Audiowide; font-size: 3.75em; font-weight: 600; text-align: center; color: #0A122A; }
ion-header .headline-two {font-family: ContrailOne; font-size: 1.8em; font-weight: 800; text-align: center; color: black;}
ion-header .headline-three {color: black;}

/*ion-header .corporate-row {padding: 2.3em 2.5em;; background-color: #FFDC9C} */
ion-header .corporate-row {padding-bottom: 1.5em;}
ion-header .round-col ion-img{ height: 5.5em; }
ion-header .social {position: relative; bottom: 5px; margin-top: 1em; text-align: center; height: 3.2em;}
ion-header .social .social-item {display:block; margin-top: .2em; height: 1.7em; color: #1C2321; font-size: 1.5em;}
ion-header .social a { text-decoration: none;}
ion-header .social .title {display: block; font-size: 18px; width: 200%; position: relative; right:50%; text-decoration: none;}
@media only screen and (max-width: 1280px) {
    ion-header ion-grid > ion-row:first-of-type {margin-top: 1.5em; margin-bottom:5.3em;}
}

@media only screen and (max-width: 1080px) {
    ion-header ion-grid > ion-row:first-of-type {margin-top: 1.5em; margin-bottom:1.3em;}
    ion-header .headline-one { font-size: 2.5rem;}
}
@media only screen and (max-width: 900px) {
    
    ion-header .header-logo { margin-top: .5em;}
    ion-header .headline-one { font-size: 2rem;}
    ion-header .headline-two { font-size: 1.5rem;}
}

@media only screen and (max-width: 810px) {
    ion-header ion-grid > ion-row:first-of-type {margin-top: 2.5em; margin-bottom: .2rem;}
    ion-header ion-col {max-height:7em; padding-bottom: .4em;}
    ion-header .header-logo { margin-top: .1em;}
    ion-header .header-logo ion-img::part(image){height: 5.75em;}
    ion-header .headline-one { font-size: 1.9rem;}
    ion-header .headline-two { font-size: 1.3rem;}
}
@media only screen and (max-width: 420px) {
    ion-header .headline-one { font-size: 1.55rem;}
    ion-header .headline-two { font-size: 1.15rem;}
}
@media only screen and (max-width: 360px) {
    
    ion-header ion-grid > ion-row:first-of-type {margin-top: .75em; margin-bottom: -.5rem;}
    ion-header .header-logo { margin-top: .1em;}
    ion-header .header-logo ion-img::part(image){height: 3.5em;}
    ion-header .headline-one { font-size: 1.25rem;}
    ion-header .headline-two { font-size: 1rem;}
}

/*********************************
 **     MAIN Body
 *********************************/
/*#main { width: 90%; max-width: 1400px; margin: auto auto; background-color: white; border: solid thick gray;  border-right: solid thick gray; box-shadow: 0em 1em 1em 1em #003844;}*/

#main-content, #main-content  { background-color: #b8b8b8;}

body, #main-content, .about {background-color: #ebebeb;}
#main {width: 90%; max-width: 1500px; margin: auto; border: solid thin rgb(153, 153, 153); box-shadow: .1em .1em 1em .1em rgb(121, 121, 121);}
section {margin-top: -1.5rem;}
section, article { padding: 4rem 0;}

.navigation {position: fixed; z-index: 111;  left: 0.9em; max-width: 70em; width: 20%; padding: 1em; margin:auto; margin-top: 1em; box-shadow: .3em .1em .1em .1em rgb(146, 146, 146); background-color: white; border: solid 1px gray; border-radius: 0 .1em .1em 0;}
.navigation.closed {width: 5%; max-width: 3.5em; padding: 0.4em;}
.navigation.closed .hide-elements { display: none;}
.navigation.closed  .toggle-nav {float: none; margin:0; }
.navigation.closed .mail-button {margin: 0; font-size: 2.2em;}

.navigation .toggle-nav {float: right; font-size: 2.2em; margin-right: .25em; margin-top: .25em; color: #F6AE2D;}
.navigation .toggle-nav:hover {cursor: pointer;}
.navigation ul{ list-style-type: none;}
.navigation ul li {  padding: 1em .3em;}
.navigation ul li:first-of-type {font-size: 1.4em;}
.navigation ul li:first-of-type a::before {display: inline-block;content: ''; width:2em; height: .1em; border-bottom: 1px black solid; background-color: #F6AE2D; margin-right: 1em;}
.navigation ul li .home-icon {position:relative; right: .4em; top: .2em; font-size: 1.25em;}
.navigation ul li:not(:first-of-type) {margin-left: 6.5em;}
.navigation ul li a { padding: 1em; color: gray; text-decoration: none;}

.navigation .mail-button { display: inline-block; margin: .3em 0 0 .5em; font-size: 3.5em; font-weight: 900; color: rgb(128, 128, 128);}


.intro-section {font-size:1.5rem; text-align: center;  width: 50%; margin: 3rem auto 1rem auto;}

.button {display: inline-block; margin-left: 1rem; font-weight: bold; font-size: 1.2rem;text-decoration: none; color:rgb(66, 66, 66); background-color:#dadada; padding: 1.5rem; border-radius: .3rem; }
.button.center { margin: auto auto;}
.skill-block h4 { position: relative; z-index:10;  font-size: 1.5rem; margin-left: 5%; text-transform: uppercase; display: block; }

.skill-block .skill-list {position: relative; z-index:10; margin-top: 0.25rem; padding: .1em;list-style: none; list-style-type: none; border-left: #F6AE2D solid 5px; border-top: #F6AE2D solid 5px; min-height: 15em;}
/*.skill-block .skill-list::before {content: '';position:absolute; display: block; height: 205px; width: 100%; border-left: #F6AE2D solid 5px; border-top: #F6AE2D solid 5px; }*/
.skill-block .skill-list li {float: left; padding: 0.8rem 1.1rem; font-size: 1.15rem; color:rgb(66, 66, 66); background-color:#dadada; margin: .5rem .8rem; border-radius: .3rem;}
.skill-block .skill-list li.next-empty{margin-right: 4em;}

.chatbot-container {position: fixed; z-index: 111; bottom: 2.8em; right: 2em; }
.chatbot-container.closed .react-chatbot-kit-chat-inner-container {display:none;}

.chatbot-container .chatbot-bubble {float: right; margin: 1em .2em; padding: .75em; width: 2em; height:2em; background-color: #F6AE2D; border: none ; border-radius: 12em; color:#ebebeb; box-shadow:#0A122A .1em .1em .4em .01em;}
.chatbot-container .chatbot-bubble:hover {cursor: pointer;}
.chatbot-container .widget-chat-about *{font-size: .9em;}
.chatbot-container .widget-chat-about ul {list-style-type: none;}
.chatbot-container .widget-chat-about ul li {float:left; margin: 0.5em;}
.chatbot-container .widget-chat-about ul li:last-of-type {float: none;}
.impressum {padding-bottom: 2em;}



@media screen and (prefers-color-scheme: dark) {
    h3, h4 {color: #fff; }
}

@media only screen and (max-width: 1281px) {
    .navigation {left: 0.6em;  width: 25%; padding: .1em; bottom: 3.4em;}
    .navigation.closed {width: 12%; max-width: 3.5em; padding: 0.em;}
}
@media only screen and (max-width: 1080px) {
    body h2 { font-size: 2rem; }
    body h2:after { height: 5px; width: 3.4rem; }
    body p {font-size: 1.2rem; }
    
    .navigation {left: 0.6em;  width: 40%; padding: .1em; bottom: 3.4em;}
    .navigation.closed {width: 12%; max-width: 3.5em; padding: 0.em;}
    
    .skill-block {margin-top: 2em;} 
    .skill-block .skill-list {min-height: 10em; margin-top: 1em;}

}
@media only screen and (max-width: 900px) {
    .skill-block .skill-list {min-height: 12em; margin-top: 1em;}
}

@media only screen and (max-width: 810px) {
    .skill-block {margin-top: 2em;} 
    .skill-block .skill-list {min-height: 14em; margin-top: 1em;}
}

@media only screen and (max-width: 430px) {
    
    body h2 { font-size: 2rem; }
    body h2:after { height: 5px; width: 3.4rem; }
    body h3 {font-size: 1.4rem; }
    #main {width: 100%}
    
    .navigation {left: 0.6em;  width: 70%; padding: .1em; bottom: 3.4em;}
    .navigation.closed {width: 12%; max-width: 3.5em; padding: 0.em;}
    
    .intro-section {font-size:1.2rem;  width: 75%; }

    .skill-block h4 { font-size:1rem; margin-top: 3em;}
    .skill-block .skill-list {min-height: 25em; margin-top: 1em;}
    .skill-block .skill-list li.next-empty{margin-right: 1.4em;}
}
@media only screen and (max-width: 360px) {
    #main {width: 100%}

    .navigation {width: 76%; }
    .navigation.closed {width: 13.5%;}
    /*.chatbot-container .chatbot-bubble {right: }*/
    .skill-block .skill-list {min-height: 25em; margin-top: 1em;}
}

/*********************************
 **     Footer
 *********************************/
/*linear-gradient(to right,rgba(245,245,245,.8),rgba(245,245,245,.8)),url(/daac8858fd4a0ccea44f59dfd079c3c16c263f33/c157c/assets/svg/common-bg.svg)*/
ion-footer{padding: 2em; }
ion-footer, ion-footer * {background-color: black; color: white!important; font-family: 'SourceSansPro',sans-serif;}
ion-footer ion-title {font-family: Audiowide; padding: 0!important;font-size: 1.6rem; font-weight: bolder;}
ion-footer ul.nav { list-style: none; list-style-type: none; } 
ion-footer ul.nav a {font-size: 1.5rem; text-decoration: none;}
ion-footer .copyright, ion-footer .copyright p {font-size: .9em; text-align: center;}

@media only screen and (max-width: 570px) {
    ion-footer .footer-main {margin-bottom:2.5em;}
    ion-footer ul {position: relative; right: 1.5em;}
}

@media only screen and (max-width: 565px)  {
    ion-footer ul {position: inherit;}
}

@media only screen and (max-width: 320px) {
    ion-footer ion-title {font-size: 1.4rem;}
    ion-footer ul.nav a {font-size: 1.3rem;}
}