.intro-container {padding:7rem 0 10rem 0; background: linear-gradient(to right,rgba(245,245,245,.8),rgba(245,245,245,.8)),url(/public/assets/img/texture-1893783_1920.jpg);}
.intro-container .avatar  {padding: .2em;}

/*.intro-container .avatar ion-img:before {position: absolute; content:""; display: block; width:30em; height: 30em;border:  dashed thick rgb(201, 201, 201); border-radius: 22em;}*/
.intro-container .avatar ion-img {border: /*#F6AE2D*/ #FFBD46  solid 8px;border-radius: 20em; background-color: white; box-shadow: .2em .3em .4em .2em black; transform: rotate(-10deg);}
.intro-container .avatar ion-img::part(image) {position: relative; right: .1em; border: solid 2px black;border-radius: 20em; transform: rotate(21deg); background-color: #f2f2f2; }

.intro-container .intro-text {font-family: 'SourceSansPro',sans-serif; text-align: left; padding: 5em 0; }
.intro-container .intro-text h1 { color: #111; font-size: 3.8rem; line-height: 1.6;letter-spacing: 3px; text-transform: uppercase;  font-weight: 900;margin-bottom: 0.6em;}
.intro-container .intro-text p {color: black; font-size: 1.5rem; line-height: 1.55;}


@media only screen and (max-width: 1280px) {
    .intro-container .intro-text h1 {font-size: 2.3rem;}
}

@media only screen and (max-width: 1080px) {
    .intro-container .avatar  {margin-top:2.25em;}

    .intro-container .intro-text {padding: 1.8em 0; font-size: 3rem;}
    .intro-container .intro-text h1 {font-size: 2.3rem;}
    .intro-container .intro-text p {font-size: 1.5rem;}
}

@media only screen and (max-width: 930px) {
    .intro-container {padding: 3.55rem 0 5rem 0}
    .intro-container .intro-text h1 {font-size: 2.1rem;}
}

@media only screen and (max-width: 918px) {
    .intro-container {padding: 4.5rem 0 7rem 0}
    .intro-container .avatar  {margin-top:.2em;}
    .intro-container .intro-text {padding: .2em 0; font-size: 3rem;}
    .intro-container .intro-text h1 {font-size: 2.2rem;}
    .intro-container .intro-text p {font-size: 1.2rem;}
}

@media only screen and (max-width: 855px) {
    .intro-container {padding: .5rem 0 7rem 0}
    .intro-container .avatar  {margin-top:4.5em;}

    .intro-container .intro-text {padding: 1.8em 0; font-size: 3rem;}
    .intro-container .intro-text h1 {font-size: 2,2rem;}
    .intro-container .intro-text p {font-size: 1.5rem;}
    
}
@media only screen and (max-width: 720px) {
    .intro-container {padding: 4.5rem 0 7rem 0}
}
@media only screen and (max-width: 570px) {
    .intro-container .avatar  {position: absolute; top: 9.8rem;  right: 1.75rem; margin-top:4.25em; height: 4em; width: 4em;}
    .intro-container .intro-text {padding: 12.4em 0 1.5em 0; font-size: 1.0rem;}
}
@media only screen and (max-width: 428px) {
    
    .intro-container .avatar  {position: absolute; top: 12.2rem;  right: 0.75rem; margin-top:4.25em; height: 4em; width: 4em;}
    .intro-container .intro-text {padding: 8.4em 0 1.5em 0; font-size: 1.0rem;}
    .intro-container .intro-text h1 {font-size: 2.2rem;}
    .intro-container .intro-text p {font-size: 1.4rem;}
}
@media only screen and (max-width: 360px) {
    .intro-container .avatar  {position: absolute; top: 11.3rem;   margin-top:2.5em; height: 5em; width: 5em;}
    .intro-container .intro-text h1 {font-size: 2rem;}
    .intro-container .intro-text p {font-size: 1.2rem;}
}